import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import Particles from 'react-tsparticles';
import { Helmet } from "react-helmet";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSun, faMoon, faArrowUp } from '@fortawesome/free-solid-svg-icons';
import './App.css';
import AboutMe from './components/AboutMe';
import Projects from './components/Projects';
import Contact from './components/Contact';
import WorkExperience from './components/WorkExperience';

function App() {
  const [darkMode, setDarkMode] = useState(false);
  const [showScroll, setShowScroll] = useState(false);

  useEffect(() => {
    document.body.className = darkMode ? 'dark-mode' : 'light-mode';
  }, [darkMode]);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 300) {
        setShowScroll(true);
      } else {
        setShowScroll(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const toggleDarkMode = () => {
    setDarkMode(!darkMode);
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <Router>
      <div className="App">
        <Helmet>
          <title>Passawit Suriyodorn | Portfolio</title>
          <meta name="description" content="Portfolio of Passawit Suriyodorn, IT/ML Solutions Engineer." />
        </Helmet>

        <Particles
          id="tsparticles"
          options={{
            background: {
              color: { value: darkMode ? "#333" : "#f0f2f5" },
            },
            particles: {
              color: { value: darkMode ? "#f0f2f5" : "#1a237e" },
              links: {
                color: darkMode ? "#f0f2f5" : "#1a237e",
                distance: 150,
                enable: true,
                opacity: 0.5,
                width: 1,
              },
              collisions: { enable: true },
              move: {
                direction: "none",
                enable: true,
                outMode: "bounce",
                speed: 2,
              },
              number: {
                density: { enable: true, area: 800 },
                value: window.innerWidth < 768 ? 30 : 80,
              },
              opacity: { value: 0.5 },
              shape: { type: "circle" },
              size: {
                random: true,
                value: window.innerWidth < 768 ? 3 : 5,
              },
            },
            detectRetina: true,
          }}
        />

        <header className="App-header">
          <img src={`${process.env.PUBLIC_URL}/profile.jpeg`} alt="Profile" className="profile-image" />
          <h1>Passawit Suriyodorn</h1>
          <p>IT/ML Solutions Engineer</p>
          <button className="dark-mode-toggle" onClick={toggleDarkMode}>
            <FontAwesomeIcon icon={darkMode ? faSun : faMoon} />
          </button>
          <nav>
            <Link to="/">About Me</Link>
            <Link to="/work-experience">Work Experience</Link>
            <Link to="/projects">Projects</Link>
            <Link to="/contact">Contact</Link>
          </nav>
        </header>

        <main>
          <Routes>
            <Route path="/" element={<AboutMe />} />
            <Route path="/work-experience" element={<WorkExperience />} />
            <Route path="/projects" element={<Projects />} />
            <Route path="/contact" element={<Contact />} />
          </Routes>
        </main>

        <footer className="App-footer">
          <p>&copy; 2024 Passawit Suriyodorn. All rights reserved.</p>
        </footer>

        {showScroll && (
          <button className="scroll-top" onClick={scrollToTop}>
            <FontAwesomeIcon icon={faArrowUp} />
          </button>
        )}
      </div>
    </Router>
  );
}

export default App;