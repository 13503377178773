import React from 'react';

function Contact() {
  return (
    <div className="App-section">
      <h2>Contact</h2>
      <div className="contact-card">
        <h3>Contact Information</h3>
        <p><i className="fas fa-user"></i> Passawit Suriyodorn</p>
        <p><i className="fas fa-map-marker-alt"></i> Live in Bangkok, Thailand</p>
        <p><i className="fas fa-map-marker-alt"></i> Live in Hannover, Germany</p>
      </div>
      <div className="contact-container">
        <a href="mailto:passawit.suriyodorn@gmail.com" className="contact-button">
          <i className="fas fa-envelope"></i> Email
        </a>
        <a href="tel:+491734073617" className="contact-button">
          <i className="fas fa-phone"></i> Phone
        </a>
        <a href="https://www.linkedin.com/in/passawit-suriyodorn/" target="_blank" rel="noopener noreferrer" className="contact-button">
          <i className="fab fa-linkedin"></i> LinkedIn
        </a>
        <a href="https://github.com/manofculturett" target="_blank" rel="noopener noreferrer" className="contact-button">
          <i className="fab fa-github"></i> GitHub
        </a>
      </div>
    </div>
  );
}

export default Contact;