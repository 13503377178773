import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBuilding, faLaptopCode, faProjectDiagram } from '@fortawesome/free-solid-svg-icons';
import { useSwipeable } from 'react-swipeable';
import './WorkExperience.css'; // Ensure you have a separate CSS file for WorkExperience

function WorkExperience() {
  const [currentIndex, setCurrentIndex] = useState(0);
  const images = [
    `${process.env.PUBLIC_URL}/airbus.png`,
    `${process.env.PUBLIC_URL}/fly.jpeg`,
    `${process.env.PUBLIC_URL}/tour.jpeg`,
    `${process.env.PUBLIC_URL}/run.jpeg`,
    `${process.env.PUBLIC_URL}/bag.jpeg`
  ];

  const handlers = useSwipeable({
    onSwipedLeft: () => setCurrentIndex((currentIndex + 1) % images.length),
    onSwipedRight: () => setCurrentIndex((currentIndex - 1 + images.length) % images.length),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true
  });

  return (
    <section className="App-section">
      <h2>Work Experience</h2>
      
      <div className="experience-timeline">
        {/* Internship Program at Airbus Aerostructures */}
        <div className="experience-item">
          <FontAwesomeIcon icon={faBuilding} className="experience-icon" />
          <div className="experience-content">
            <h4>Internship Program, Airbus Aerostructures (CTC GmbH)</h4>
            <p><em>Jun - Oct 2024</em></p>
            <p>
              Introduced <strong>Siemens Industrial Edge Solutions</strong> to enhance data connectivity through PLCs, 
              optimizing data flow. Managed the data pipeline and designed a <strong>dashboard</strong> for ongoing LaiLa projects, 
              specifically the <strong>Thermoplastic Joining Cobot</strong>.
            </p>
            <p>
              Developed and deployed a <strong>custom containerized Python app</strong> for automated anomaly detection 
              using machine learning, enhancing production efficiency and reducing downtime for Universal Robots.
            </p>
          </div>
        </div>

        {/* Teaching and Research Assistant */}
        <div className="experience-item">
          <FontAwesomeIcon icon={faLaptopCode} className="experience-icon" />
          <div className="experience-content">
            <h4>Teaching and Research Assistant, Institute for Information Processing (TNT)</h4>
            <p><em>Jan 2023 - 2024</em></p>
            <p>
              Utilized tools like <strong>scikit-learn</strong> and <strong>XGBoost</strong> for research on cholesterol reduction 
              in the cardiovascular system, achieving <strong>up to 70% accuracy</strong>.
            </p>
            <p>
              Led weekly discussions for the course <em>“Grundzüge der Informatik und Programmierung”</em> and guided 
              students through <strong>C and Python programming</strong>.
            </p>
          </div>
        </div>
      </div>

      {/* Image Stack */}
      <div className="image-stack" {...handlers}>
        <img src={images[currentIndex]} alt={`Airbus`} className="stack-image" />
      </div>
      <div className="button-container">
        <button onClick={() => setCurrentIndex((currentIndex - 1 + images.length) % images.length)}>Previous</button>
        <button onClick={() => setCurrentIndex((currentIndex + 1) % images.length)}>Next</button>
      </div>
      {/* Personal Projects */}
      <h3><FontAwesomeIcon icon={faProjectDiagram} /> Personal Projects</h3>
      <p>
        Applied machine learning algorithms like <strong>Scikit-Learn</strong>, <strong>Random Forest</strong>, and 
        <strong>XGBoost</strong> to develop a model predicting cholesterol levels.
      </p>
      <p>
        Implemented <strong>anomaly detection</strong> solutions using <strong>TODS</strong> and <strong>DeepLog</strong>, 
        which enabled effective identification of anomalies in time-series data, contributing to enhanced monitoring and 
        maintenance in industrial applications.
      </p>
      <p>
        Developed a <strong>web scraping tool</strong> using <strong>Python</strong> and <strong>BeautifulSoup</strong> to extract 
        data from various websites, which was then analyzed to provide insights on market trends.
      </p>
      <p>
        Created a <strong>full-stack web application</strong> using <strong>React</strong> and <strong>Node.js</strong> for managing 
        personal finances, featuring user authentication, expense tracking, and data visualization.
      </p>
      <p>
        More on <a href="Projects">Projects</a>.
      </p>
    </section>
  );
}

export default WorkExperience;