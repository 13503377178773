import React from 'react';

function AboutMe() {
  return (
    <section className="App-section">
      <div className="profile-container">
        <img 
          src={`${process.env.PUBLIC_URL}/profile.jpeg`} 
          alt="Profile" 
          className="profile-image" 
        />
      </div>

      <h2>About Me</h2>
      <p>
        I’m an engineer with expertise in data analysis and machine learning, backed by a solid foundation in 
        Mechatronics and Software Engineering. I have experience developing data-driven solutions, including 
        predictive models and real-time anomaly detection, with hands-on experience in cloud platforms.
      </p>
      <p>
        In addition to my data expertise, I bring practical skills in industrial automation, including PLC programming 
        and edge computing, enabling efficient integration of data insights into industrial environments.
      </p>
      <h3>Education</h3>
<ul>
  <li>
    <strong>B.Sc. in Mechatronics</strong>, specialized in Machine Learning and Computer Vision<br />
    Leibniz University Hannover, 2019 - 2023
  </li>
</ul>

      <h3>Skills</h3>
      <ul>
        <li><strong>Languages:</strong> C++, Python, JavaScript</li>
        <li><strong>Database:</strong> SQL, InfluxDB</li>
        <li><strong>Containers:</strong> Docker, Kubernetes</li>
        <li><strong>Cloud:</strong> AWS, Google Cloud</li>
        <li><strong>Machine Learning:</strong> scikit-learn, RandomForest, XGBoost</li>
        <li><strong>Neural Networks:</strong> PyTorch</li>
        <li><strong>Industrial Automation:</strong> Siemens PLC (TIA Portal, WinCC), Universal Robots 10e</li>
      </ul>


      <h3>Hobbies</h3>
      <ul>
        <li>Cooking</li>
        <li>IT Projects</li>
      </ul>
    </section>
  );
}

export default AboutMe;
